import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Link, graphql } from "gatsby"

export default function menu({ data }) {
  return (
    <section className="mx-6">
      <header className="flex justify-center w-full mt-5 mb-10">
        <Link to="/">
          <StaticImage
            src="../images/Logo-new-Bordeaux.png"
            loading="eager"
            width={95}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Logo"
          />
        </Link>
      </header>

      <div className="grid grid-cols-2 gap-4">
        {(data?.allStrapiCategories?.nodes || []).map((menu, idx) => {
          return (
            <div
              key={idx}
              className="rounded-full border-black bg-[#490E13] p-6"
            >
              <Link to={menu?.slug} className="flex items-center flex-col">
                {/* <img
                  className="w-[50%] h-auto pb-2 m-0"
                  src={`https://admin.thebrewhouseintrobio.it${menu?.icona?.url}`}
                  alt={menu?.titolo}
                /> */}

                <span className="text-center block text-white">
                  {menu?.titolo}
                </span>
              </Link>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const query = graphql`
  query {
    allStrapiCategories {
      nodes {
        titolo
        slug
      }
    }
  }
`
